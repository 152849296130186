import { useState, useEffect } from "react";
import { supabase } from "./supabase";

const App = () => {
  const [session, setSession] = useState("")
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async (email, password) => {
    try {
      setLoading(true)
      const { data, error } = await supabase.auth.signInWithPassword(
        {
          email: email,
          password: password,
        })
      if (error) throw error
      alert('Sign In OK')
    } catch (error) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }
  const handleSignUp = async (email, password) => {
    try {
      setLoading(true)
      const { error } = await supabase.auth.signUp({
        email: email,
        password: password,
      })
      if (error) throw error
      alert('登録されたメールアドレスへ認証用リンクを送付しました。\n認証用リンクから本登録を完了させて下さい。')
    } catch (error) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut()
    console.log({ error })
  }

  const fetchSession = async () => {
    const { data, error } = await supabase.auth.getSession()
    console.log(data)
  }


  useEffect(() => {
    (async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      setSession(session);
    })();

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  console.log(session)

  return (
    <>
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
          <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-8">SupaBase</h2>

          <form className="max-w-lg border rounded-lg mx-auto">
            <div className="flex flex-col gap-4 p-4 md:p-8">
              <div>
                <label for="email" className="inline-block text-gray-800 text-sm sm:text-base mb-2">Email</label>
                <input
                  name="email"
                  className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label for="password" className="inline-block text-gray-800 text-sm sm:text-base mb-2">Password</label>
                <input
                  name="password"
                  type="password"
                  className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {!session ? (
                <button
                  className="block bg-gray-800 hover:bg-gray-700 active:bg-gray-600 focus-visible:ring ring-gray-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3"
                  onClick={(e) => {
                    e.preventDefault()
                    handleLogin(email, password)
                  }}
                >
                  {loading ? 'Loading' : 'ログイン'}
                </button>
              ) : (
                <button
                  className="block bg-gray-800 hover:bg-gray-700 active:bg-gray-600 focus-visible:ring ring-gray-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3"
                  onClick={(e) => {
                    e.preventDefault()
                    handleLogout()
                  }}
                >
                  ログアウト
                </button>
              )}

              <button
                className="block bg-gray-800 hover:bg-gray-700 active:bg-gray-600 focus-visible:ring ring-gray-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3"
                onClick={(e) => {
                  e.preventDefault()
                  handleSignUp(email, password)
                }}
              >
                {loading ? 'Loading' : '新規登録'}
              </button>

            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default App;
